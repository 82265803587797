export function isAllowed(countryCode: string): boolean {
  /**
   * There are separate allow list for visa & mastercard
   * But right now, both lists contain same countries
   */
  const allowedCountries: Record<string, string> = {
    GP: "Guadeloupe",
    AO: "Angola",
    KI: "Kiribati",
    CA: "Canada",
    CM: "Cameroon",
    QA: "Qatar",
    VU: "Vanuatu",
    EC: "Ecuador",
    AE: "United Arab Emirates",
    KZ: "Kazakhstan",
    GI: "Gibraltar",
    ID: "Indonesia",
    BG: "Bulgaria",
    BE: "Belgium",
    CO: "Colombia",
    BR: "Brazil",
    IT: "Italy",
    EG: "Egypt",
    KR: "South Korea",
    ZA: "South Africa",
    OM: "Oman",
    NF: "Norfolk Island",
    SK: "Slovak Republic",
    SB: "Solomon Islands",
    LU: "Luxembourg",
    //cspell:disable-next-line
    BV: "Bouvet Island",
    GG: "Guernsey",
    GD: "Grenada",
    AW: "Aruba",
    JM: "Jamaica",
    GF: "French Guiana",
    NO: "Norway",
    LI: "Liechtenstein",
    HK: "Hong Kong",
    HU: "Hungary",
    PE: "Peru",
    AM: "Armenia",
    NL: "Netherlands",
    BN: "Brunei Darussalam",
    BM: "Bermuda",
    VI: "Virgin Islands (U.S.)",
    MS: "Montserrat",
    GY: "Guyana",
    DJ: "Djibouti",
    PL: "Poland",
    DK: "Denmark",
    FM: "Federated States of Micronesia",
    GH: "Ghana",
    AD: "Andorra",
    PF: "French Polynesia",
    LV: "Latvia",
    AZ: "Azerbaijan",
    TO: "Tonga",
    CC: "Cocos (Keeling) Islands",
    MR: "Mauritania",
    IL: "Israel",
    GB: "UK",
    LT: "Lithuania",
    CL: "Chile",
    DE: "Germany",
    IN: "India",
    BW: "Botswana",
    IS: "Iceland",
    FI: "Finland",
    BH: "Bahrain",
    US: "USA",
    AI: "Anguilla",
    CZ: "Czech Republic",
    ES: "Spain",
    MT: "Malta",
    SG: "Singapore",
    DZ: "Algeria",
    CY: "Cyprus",
    YT: "Mayotte",
    MP: "Northern Mariana Islands",
    HR: "Croatia",
    CK: "Cook Islands",
    GL: "Greenland",
    SA: "Saudi Arabia",
    GT: "Guatemala",
    MY: "Malaysia",
    JO: "Jordan",
    IE: "Ireland",
    CX: "Christmas Island",
    RO: "Romania",
    NZ: "New Zealand",
    SV: "El Salvador",
    FR: "France",
    KE: "Kenya",
    DM: "Dominica",
    MA: "Morocco",
    TW: "Taiwan",
    NU: "Niue",
    JP: "Japan",
    PH: "Philippines",
    GE: "Georgia",
    GU: "Guam",
    PW: "Palau",
    AU: "Australia",
    AS: "American Samoa",
    //cspell:disable-next-line
    CR: "Costa Rica",
    GM: "Gambia",
    TH: "Thailand",
    //cspell:disable-next-line
    CW: "Curaçao",
    MQ: "Martinique",
    PR: "Puerto Rico",
    BQ: "Caribbean Netherlands",
    BZ: "Belize",
    MW: "Malawi",
    NE: "Niger",
    SI: "Slovenia",
    AT: "Austria",
    MX: "Mexico",
    LK: "Sri lanka",
    PT: "Portugal",
    FK: "Falkland Islands",
    UY: "Uruguay",
    IO: "British Indian Ocean Territory",
    GR: "Greece",
    VA: "Vatican City",
    AQ: "Antarctica",
    FO: "Faroe Islands",
    EE: "Estonia",
    TV: "Tuvalu",
    TF: "French Southern Territories",
    SE: "Sweden",
    AG: "Antigua and Barbuda",
    BO: "Bolivia",
    GA: "Gabon",
    TM: "Turkmenistan",
    AR: "Argentina",
    JE: "Jersey",
    //cspell:disable-next-line
    CV: "Cabo Verde",
    HM: "Heard Island and McDonald Islands",
    BD: "Bangladesh",
    BT: "Bhutan",
    FJ: "Fiji",
    CH: "Switzerland",
    MC: "Monaco",
  };

  return Object.hasOwn(allowedCountries, countryCode);
}
